<template>
  <div class="score-query">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon
            class="btn"
            v-show="!pageStatus.changeStatus"
            @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
          <el-icon
            class="btn"
            v-show="pageStatus.changeStatus"
            @click="pageStatus.changeStatus = false"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        <span v-if="pageStatus.num === 1">修改账号密码</span>
        <span v-else-if="pageStatus.num === 2">修改安全邮箱</span>
        <span v-else-if="pageStatus.num === 3">修改安全手机</span>
        <span v-else>账户安全</span>
      </el-col>
    </el-row>
    <el-row class="message-content" v-if="pageStatus.changeStatus">
      <el-col>
        <div v-if="pageStatus.num === 1">
          <el-form :model="form" label-width="120px" class="form">
            <el-form-item label="旧密码">
              <el-input
                type="password"
                v-model="form.oldPwd"
                placeholder="请输入旧密码"
              />
            </el-form-item>
            <el-form-item label="新密码">
              <el-input
                type="password"
                v-model="form.newPwd"
                placeholder="请输入新密码"
              />
            </el-form-item>
            <el-form-item label="验证方式">
              <el-radio-group v-model="form.safeNumStatus">
                <el-radio label="phone"
                  >手机验证<span
                    >({{ state.phone || "未绑定" }})</span
                  ></el-radio
                >
                <el-radio :disabled="!state.email" label="email"
                  >邮箱验证<span
                    >({{ state.email || "未绑定" }})</span
                  ></el-radio
                >
              </el-radio-group>
              <el-button @click="selectSendCode">获取验证码</el-button>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input
                v-model="form.verCode"
                placeholder="请输入验证码"
                style="margin-bottom: 10px"
              />
            </el-form-item>
            <el-form-item>
              <el-button @click="onSubmit">保存</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="pageStatus.num === 2">
          <el-form :model="form" label-width="120px" class="form">
            <el-form-item label="邮箱">
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
                style="margin-bottom: 10px"
              />
              <el-button @click="sendCode">获取验证码</el-button>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input
                v-model="form.emailVerCode"
                placeholder="请输入验证码"
                style="margin-bottom: 10px"
              />
            </el-form-item>
            <el-form-item>
              <el-button @click="bindEmail">保存</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="pageStatus.num === 3">
          <el-form :model="form" label-width="120px" class="form">
            <el-form-item label="手机号">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                style="margin-bottom: 10px"
              />
              <el-button @click="sendPhoneCode">获取验证码</el-button>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input
                v-model="form.phoneVerCode"
                placeholder="请输入验证码"
                style="margin-bottom: 10px"
              />
            </el-form-item>
            <el-form-item>
              <el-button @click="bindPhone">保存</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-row class="message-content" v-else>
      <el-col>
        <div class="message-item">
          <div class="message-item-left">
            <img src="../../../assets/portal/lock.png" alt="" />
          </div>

          <div class="message-item-right">
            <div>账号密码</div>
            <div>用于保护账号信息和登录安全</div>
          </div>
          <div class="message-item-btn">
            <el-button @click="change(1)">修改</el-button>
          </div>
        </div>
        <div class="message-item">
          <div class="message-item-left">
            <img src="../../../assets/portal/Email.png" alt="" />
          </div>

          <div class="message-item-right">
            <div>
              安全邮箱<span>({{ state.email || "未绑定" }})</span>
            </div>
            <div>安全邮箱可以用于登录账号，重置密码或其他安全验证</div>
          </div>
          <div class="message-item-btn">
            <el-button @click="change(2)">修改</el-button>
          </div>
        </div>
        <div class="message-item">
          <div class="message-item-left">
            <img src="../../../assets/portal/phone.png" alt="" />
          </div>

          <div class="message-item-right">
            <div>
              安全手机<span>({{ state.phone || "未绑定" }})</span>
            </div>
            <div>安全邮箱可以用于登录账号，重置密码或其他安全验证</div>
          </div>
          <div class="message-item-btn">
            <el-button @click="change(3)">修改</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import changePasswordHook from "./hooks/changePasswordHook";
import { getCode, decrypt } from "@/utils/encrypt.js";
const { changePassword } = changePasswordHook();
let store = useStore();
onMounted(() => {
  getUserSafeInfo()
})
// data
let state = reactive({
  email: '',
  phone: ''
})
let form = reactive({
  oldPwd: '',
  newPwd: '',
  email: '', // 邮箱
  emailVerCode: '', // 邮箱验证码
  phone: '', // 手机号码
  userId: store.state.user.userId, // 用户Id,
  safeNum: '',
  safeNumStatus: 'phone',
  verCode: ''
})
let pageStatus = reactive({
  num: 0,
  changeStatus: false
})
// methods

// 发送邮箱验证码
const sendCode = async () => {
  if (form.email) {
    const res = await get("/examApply/user/sendEmailCode", { email: form.email })
    if (res.code === 200) {
      ElMessage.success(res.message)
    }
  } else {
    ElMessage.error('请输入邮箱')
  }
};
// 发送手机验证码
const sendPhoneCode = async () => {
  if (form.phone) {
    const res = await get("/examApply/user/sendPhoneCode", { phone: form.phone })
    if (res.code === 200) {
      ElMessage.success(res.message)
    }
  } else {
    ElMessage.error('请输入手机号')
  }
};
// 保存安全邮箱
const bindEmail = async () => {
  const res = await post("/examApply/user/bindEmail", {
    email: form.email,
    emailVerCode: form.emailVerCode,
    userId: store.state.user.userId
  })
  if (res.code === 200) {
    ElMessage.success(res.message)
    back()
  }
};
// 保存安全手机号
const bindPhone = async () => {
  const res = await post("/examApply/user/bindPhone", {
    phoneNum: form.phone,
    phoneVerCode: form.phoneVerCode,
    userId: store.state.user.userId
  })
  if (res.code === 200) {
    ElMessage.success(res.message)
    back()
  }
};
// 查询考生账号安全信息
const getUserSafeInfo = async () => {
  const res = await get("/examApply/user/getUserSafeInfo")
  if (res.code === 200) {
    Object.assign(form, res.result)
    state.email = res.result.email
    state.phone = res.result.phone
  }
};

// const safeNumStatusChange = () => {
//   if (form.safeNumStatus === 'phone') {
//     form.safeNum = form.phone
//   }
//   if (form.safeNumStatus === 'email') {
//     form.safeNum = form.email
//   }
// };
const selectSendCode = () => {
  if (form.safeNumStatus === 'phone') {
    sendPhoneCode()
  }
  if (form.safeNumStatus === 'email') {
    sendCode()
  }
};
const onSubmit = async () => {
  if (form.safeNumStatus === 'phone') {
    form.safeNum = form.phone
  }
  if (form.safeNumStatus === 'email') {
    form.safeNum = form.email
  }
  const res = await post("/examApply/user/changePwd", {
    newPwd: getCode(form.newPwd),
    oldPwd: getCode(form.oldPwd),
    safeNum: form.safeNum,
    userId: store.state.user.userId,
    verCode: form.verCode,
  })
  if (res.code === 200) {
    ElMessage.success('密码修改成功，请重新登录')
    store.commit("user/logout");
  } else {
    ElMessage.error(res.message)
  }

};

const deleteString = (obj) => {
  for (const key in obj) {
    if (obj[key] === '' && obj[key] !== 0) {
      delete obj[key]
    }
  }
  return obj
};
function change (num) {
  pageStatus.changeStatus = true
  pageStatus.num = num
}
// 返回
const back = () => {
  form.phoneVerCode = ''
  form.emailVerCode = ''
  getUserSafeInfo()
  pageStatus.changeStatus = false
  pageStatus.num = 0
};
</script>
<style lang="scss" scoped>
.score-query {
  height: 89vh;
  background-color: #ffffff;
}
.message-content {
  padding: 0 0.3125rem;
}
.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.1042rem;

  .btn {
    position: absolute;
    left: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  height: 0.4896rem;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 0.0208rem;
  margin-bottom: 0.1042rem;
  //   cursor: pointer;
  .message-item-left {
    width: 10%;
    text-align: center;
    padding-top: 0.1563rem;
    img {
      vertical-align: middle;
    }
  }
  .message-item-right {
    width: 80%;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding-right: 0.1042rem;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:nth-child(1) {
      font-size: 0.0938rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin-bottom: 8px;
    }
    div:nth-child(2) {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .message-item-btn {
    width: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0.1042rem;
  }
}
// .message-item:hover {
//   background-color: #eee;
// }
.el-button {
  color: #097afa;
  border-color: #097afa;
}
.form {
  padding: 0.0521rem 1.5625rem;
  .el-form-item {
    padding: 0.0521rem 0;
  }
}
</style>