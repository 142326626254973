// usePerson.ts
import { reactive, watch } from "vue";

export default function changePasswordHook() {
  function changePassword() {
    console.log("123321");
  }
  return {
    changePassword,
  };
}
